'use strict'
var __awaiter =
  (this && this.__awaiter) ||
  function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P
        ? value
        : new P(function (resolve) {
            resolve(value)
          })
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value))
        } catch (e) {
          reject(e)
        }
      }
      function rejected(value) {
        try {
          step(generator['throw'](value))
        } catch (e) {
          reject(e)
        }
      }
      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected)
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next())
    })
  }
Object.defineProperty(exports, '__esModule', { value: true })
const injected_connector_1 = require('@telekomconsalting/injected-connector')
const walletconnect_connector_1 = require('@telekomconsalting/walletconnect-connector')
const eagerConnect_1 = require('./eagerConnect')
const types_1 = require('./types')
class WalletConnectors {
  constructor(networksConfig) {
    this.connectors = {
      MetaMask: undefined,
      WalletConnect: undefined,
      TrustWallet: undefined,
      Unknown: undefined,
    }
    this.createInjectedConnector = (networkConfig) =>
      new injected_connector_1.InjectedConnector({
        supportedChainIds: networkConfig.map((x) => x.id),
      })
    this.createWalletConnectConnector = (networkConfig) => {
      const rpcUrlMap = {}
      networkConfig.forEach((x) => (rpcUrlMap[x.id] = x.rpc_url))
      return new walletconnect_connector_1.WalletConnectConnector({
        rpc: rpcUrlMap,
        qrcode: true,
        supportedChainIds: networkConfig.map((x) => x.id),
        bridge: 'https://bridge.walletconnect.org',
      })
    }
    this.getInjectedConnector = () => this.injectedConnector
    this.getConnectors = () => this.connectors
    this.closeAllConnectors = () =>
      __awaiter(this, void 0, void 0, function* () {
        for (const k in this.connectors) {
          const connector = this.connectors[k]
          if (connector instanceof walletconnect_connector_1.WalletConnectConnector) {
            yield connector === null || connector === void 0 ? void 0 : connector.close()
          }
          yield connector === null || connector === void 0 ? void 0 : connector.deactivate()
        }
      })
    this.closeAllConnectorsButGiven = (givenConnector) =>
      __awaiter(this, void 0, void 0, function* () {
        for (const k in this.connectors) {
          const connector = this.connectors[k]
          if (givenConnector === connector) {
            return
          }
          if (connector instanceof walletconnect_connector_1.WalletConnectConnector) {
            yield connector === null || connector === void 0 ? void 0 : connector.close()
          }
          yield connector === null || connector === void 0 ? void 0 : connector.deactivate()
        }
      })
    this.disconnect = (connector, deactivate) =>
      __awaiter(this, void 0, void 0, function* () {
        if (!connector) {
          return
        }
        yield this.closeAllConnectors()
        deactivate()
        ;(0, eagerConnect_1.setSuppressEagerConnection)(true)
      })
    this.connect = (currentConnector, activate, onError) =>
      __awaiter(this, void 0, void 0, function* () {
        yield this.closeAllConnectorsButGiven(currentConnector)
        yield activate(currentConnector, onError || console.error)
        ;(0, eagerConnect_1.setSuppressEagerConnection)(false)
      })
    this.getProviderName = (connector) =>
      __awaiter(this, void 0, void 0, function* () {
        if (!connector) {
          return undefined
        }
        for (const k in this.connectors) {
          const knownConnector = this.connectors[k]
          if (connector instanceof injected_connector_1.InjectedConnector) {
            return this.getInjectedProviderName(connector)
          }
          if (connector === knownConnector) {
            return types_1.ProviderName[k]
          }
        }
      })
    this.getInjectedProviderName = (connector) =>
      __awaiter(this, void 0, void 0, function* () {
        const provider = yield connector.getProvider()
        if (provider.isTrust) {
          return types_1.ProviderName.TrustWallet
        }
        if (provider.isMetaMask) {
          return types_1.ProviderName.MetaMask
        }
      })
    this.disconnectProvider = (account, connector, deactivate, onDisconnect) =>
      __awaiter(this, void 0, void 0, function* () {
        this.disconnect(connector, deactivate)
        if (account && connector) {
          const currentProviderName = yield this.getProviderName(connector)
          if (onDisconnect) {
            yield onDisconnect(account, currentProviderName)
          }
        }
      })
    this.getProviderConnector = (providerName) => this.connectors[providerName]
    this.injectedConnector = this.createInjectedConnector(networksConfig)
    this.connectors[types_1.ProviderName.MetaMask] = this.injectedConnector
    this.connectors[types_1.ProviderName.TrustWallet] = this.injectedConnector
    this.walletConnectConnector = this.createWalletConnectConnector(networksConfig)
    this.connectors[types_1.ProviderName.WalletConnect] = this.walletConnectConnector
  }
}
exports.default = WalletConnectors
